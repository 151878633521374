import React from 'react';
import { graphql } from 'gatsby';

import { TutorialVideoHubPageTemplate } from '../templates';
import { Layout } from '../components';

const TutorialVideoHubPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const videos = data.allMarkdownRemark.edges.map(({ node }) => node.frontmatter);
    return (
        <Layout title={ frontmatter.headerTitle }>
            <TutorialVideoHubPageTemplate { ...frontmatter } videos={ videos }/>
        </Layout>
    );
};

export default TutorialVideoHubPage;

export const pageQuery = graphql`
    query TutorialVideoHubPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "tutorial-video-hub-page" } }) {
            frontmatter {
                headerImage {
                    cloudinaryMedia
                    assetType
                    deliveryType
                    urlRemainder
                    sourceURL
                }
                headerTitle
                heroTitle
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "tutorial-video" } } }
            sort: { fields: [ frontmatter___title ] }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        description
                        thumbnail {
                            assetType
                            cloudinaryMedia
                            deliveryType
                            sourceURL
                            urlRemainder
                        }
                        video {
                            assetType
                            cloudinaryMedia
                            deliveryType
                            sourceURL
                            urlRemainder
                        }
                        pdf
                        category
                    }
                }
            }
        }
    }
`;